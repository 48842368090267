import Cookies from 'js-cookie';

export class Prices {
  static start() {
    $(document).on('click', '#toggle_prices', function(event){
      event.preventDefault();
      $('.hideableprice').removeClass('d-none').show(500);
      $('#toggle_prices').hide();
    });

    $(document).on('click', '.toggle-vat', function(event){
      event.preventDefault();
      if ((Cookies.get('prices_with_vat') == 1) || (Cookies.get('prices_with_vat') == '1')) {
        $('.ex-vat').removeClass('d-none');
        $('.inc-vat').addClass('d-none');
        Cookies.set('prices_with_vat', 0);
      } else {
        $('.inc-vat').removeClass('d-none');
        $('.ex-vat').addClass('d-none');
        Cookies.set('prices_with_vat', 1);
      }
    });

    $(document).on('click', '.toggle-vat-no-cookie', function(event){
      event.preventDefault();
      if ($($('.ex-vat')[0]).is(':visible')) {
        $('.inc-vat').removeClass('d-none');
        $('.ex-vat').addClass('d-none');
      } else {
        $('.ex-vat').removeClass('d-none');
        $('.inc-vat').addClass('d-none');
      }
    });

  }
}
