import Cookies from 'js-cookie';
export class PointTrigger {
  static start() {
    const el = document.querySelector('.point-toggler');
    if (el == undefined) {
      console.log('Point toggler missing');
      return;
    }
    el.addEventListener('click', function() {
      if (typeof Point !== 'undefined') {
        Point.togglePoint();
        var point_toggler_img = el.querySelector('img');
        if (point_toggler_img) {
          if (Point.pointActive) {
            point_toggler_img.setAttribute('src', point_toggler_img.getAttribute('data-on-image'))
          } else {
            point_toggler_img.setAttribute('src', point_toggler_img.getAttribute('data-off-image'))
          }
        }
      }
    });

    if (typeof Point !== 'undefined') {
      var point_toggler_img = document.querySelector('.point-toggler img');
      if (point_toggler_img) {
        if (Cookies.get('point_enabled')==undefined) {
          Cookies.set('point_enabled', true)
        }
        if (Cookies.get('point_enabled')=='true') {
          point_toggler_img.setAttribute('src', point_toggler_img.getAttribute('data-on-image'))
        } else {
          point_toggler_img.setAttribute('src', point_toggler_img.getAttribute('data-off-image'))
        }
      }
    }
  }
}
