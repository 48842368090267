import $ from 'jquery';
import 'slick-carousel';
export class SlickSliders {
  static start() {
    $('.single-slick-carousel').slick({
      rows: 0,
      dots: true
    });

    if ($('.content-slick-slider').length > 0) {
      $('.content-slick-slider .sliderpage').removeClass('d-none');
      $('.content-slick-slider').slick({
        rows:          0,
        autoplay:      false,
        autoplaySpeed: 3500,
        dots:          true
      });
      // Not needed after 1.9.0 upgrade and using display flex on track
      // .on('setPosition', function (event, slick) {
      // slick.$slideTrack.children().css('height', slick.$slideTrack.height() + 'px');
      // });
      //.on('init', function (event, slick) {
      //   slick.$slides.css('height', slick.$slideTrack.height() + 'px');
      // });
    }
    $('.cards-slider').slick({
      rows:           0,
      dots:           true,
      infinite:       true,
      speed:          300,
      autoplaySpeed:  3500,
      slidesToShow:   4,
      slidesToScroll: 4,

      responsive: [
        {
          breakpoint: 1000,
          settings:   {
            slidesToShow:   3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 600,
          settings:   {
            slidesToShow:   2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings:   {
            slidesToShow:   1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });
  }
}
