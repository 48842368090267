// TODO: move away from moment js on user-side, as it's huge!

import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/nn';
import 'moment/locale/da';
import 'moment/locale/en-gb';

export class ShowDate {
  constructor(field) {
    this.field = $(field);
    this.updateVisibleDate();
    return this;
  }
  updateVisibleDate() {
    let from_now, m, str_date, time_stamp, format;
    // Read from date/time from timestamp
    time_stamp = this.field.attr('data-timestamp');
    // Read from date in format YYYYMMDD
    str_date = this.field.attr('data-strdate');
    // format to output, defaults to LL
    format = this.field.attr('data-format');
    from_now = this.field.attr('data-from-now');
    if (time_stamp !== void 0) {
      m = moment.unix(parseInt(time_stamp));
    }
    else if (str_date !== void 0) {
      m = moment(str_date, 'YYYYMMDD');
    }
    else {
      return;
    }
    if (time_stamp !== void 0) {
      if (from_now !== undefined) {
        this.field.html(m.fromNow());
      }
      else {
        if (format !== void 0) {
          this.field.html(m.format(format));
        }
        else {
          this.field.html(m.format('LL'));
        }
      }
    }
  }

  static start() {
    // navigator.language = navigator.language || (navigator.userLanguage && navigator.userLanguage.replace(/-[a-z]{2}$/, String.prototype.toUpperCase)) || 'nb-NO';
    // moment.locale('nb');
    moment.locale(navigator.language);
    $('.date-show').each(function(index, elem) {
      var sd;
      sd = new ShowDate(elem);
      sd = null;
    });
  }
}
