import Cookies from 'js-cookie';
import { SymboliaTools } from 'common/symbolia_tools';
export class SymSorter {
  static __sti_sorter = null;

  constructor() {
    this.setupEventListeners();
  }

  destroy() {
    this.removeEventListeners();
  }

  setupEventListeners() {
    $(document).on('click', '.sort-order-btn', $.proxy(this.applySortOrder, this));
    $(document).on('click', '.view-type-btn', $.proxy(this.applyViewType, this));
  }

  removeEventListeners() {
    $(document).off('click', '.sort-order-btn', $.proxy(this.applySortOrder, this));
    $(document).off('click', '.view-type-btn', $.proxy(this.applyViewType, this));
  }

  applySortOrder(event) {
    let new_dom = '';
    let sort_arr = [];
    let filler_arr = [];
    let $_el = null;
    let button_target = $(event.currentTarget);
    let sortable_holder = $('.sym-sortable-holder');
    let sorttype = button_target.attr('data-sort-style');
    let integer_sorting = false;
    sortable_holder.attr('data-sortable-order', sorttype);
    this.setActiveButton(button_target, '.sort-order-btn');
    Cookies.set(`${button_target.attr('data-target')}_sort`, sorttype);

    // Build array of sort elements
    $('.sym-sortable-holder').find('.sym-sortable').each(function(index, el) {
      $_el = $(el);
      sort_arr.push({ order: $_el.attr(`data-${sorttype}`), dom: $_el.get(0).outerHTML });
    });

    // Build array of flex filler elements
    $('.sym-sortable-holder').find('.flexfiller').each(function(index, el) {
      $_el = $(el);
      filler_arr.push({ dom: $_el.get(0).outerHTML });
    });

    // check if order is integer based
    if (SymboliaTools.isInt(sort_arr[0].order)) {
      integer_sorting = true;
    }
    // Sort elements
    sort_arr.sort( (a,b) => {
      // if int, use value, else lowercase string
      if (integer_sorting) {
        return parseInt(a.order)-parseInt(b.order);
      } else {
        if (a.order.toLowerCase() < b.order.toLowerCase()) { return -1; }
        if (a.order.toLowerCase() > b.order.toLowerCase()) { return  1; }
      }
      return 0; // equal...
    });

    // Build new dom
    sort_arr.forEach(function(el, index) {
      new_dom += el.dom;
    });
    // Add flex fillers at the end (if any)
    filler_arr.forEach(function(el, index) {
      new_dom += el.dom;
    });

    // Replace dom with 'flashing'
    sortable_holder.html(new_dom);
    SymboliaTools.lazyLoadImages();
  }

  setActiveButton($button, button_main_class) {
    $(button_main_class).removeClass('active');
    $button.addClass('active');
  }

  applyViewType(event) {
    let button_target = $(event.currentTarget);
    let view_type = button_target.attr('data-view-type')
    if (view_type == 'list'){
      $('.sym-sortable-holder').addClass('vertical-list');
    } else {
      $('.sym-sortable-holder').removeClass('vertical-list');
    }
    Cookies.set(`${button_target.attr('data-target')}_view_type`, view_type);
    this.setActiveButton(button_target, '.view-type-btn');
    SymboliaTools.lazyLoadImages();
  }

  static start() {
    if (SymSorter.__sti_sorter !== null) {
      SymSorter.__sti_sorter.destroy();
      SymSorter.__sti_sorter = null;
    }
    if ($('.sym-sortable-holder').length > 0) {
      SymSorter.__sti_sorter = new SymSorter();
    };
  }
}

