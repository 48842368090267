import $ from 'jquery';
import 'magnific-popup';
import PNotify from 'pnotify/lib/es/PNotify.js';
PNotify.defaults.styling  = 'bootstrap4';

import accounting from 'accounting';
export class CartController {
  static setup() {
    CartController._flashing = false;
    CartController.setupEventListeners();
    CartController.setupCartModal();
  }

  static doAjax(action, price_id, quantity = 0) {
    $.ajax({
      url:         `/cart/${action}.json`,
      type:        'POST',
      dataType:    'json',
      contentType: 'application/json',
      action:      action,
      data:        JSON.stringify({price_id: price_id, quantity: quantity, authenticity_token: CartController.authenticityToken})
    })
      .done(function(response) {
      // Always update the cart counter
        CartController.updateCartCounter(response.cart.cart_count);
        let cart_line = $(`tr[data-price-id=${response.price.id}]`)

        switch(this.action){
          case 'add':
            CartController.openCartModal();
            CartController.addItemToCartModal(response, cart_line);
            break;
          case 'quantity':
          // Find line to update
            cart_line.attr('data-quantity', response.price.quantity);
            cart_line.find('.cart-line-quantity').text(response.price.quantity);
            cart_line.find('.cart-line-sum.ex-vat').text(accounting.formatMoney(response.price.total_price, { symbol: 'kr',  format: '%v %s', thousand: ' ', precision: 0 }));
            cart_line.find('.cart-line-sum.inc-vat').text(accounting.formatMoney(response.price.total_price_vat, { symbol: 'kr',  format: '%v %s', thousand: ' ', precision: 0 }));
            // CartController.setQuantityOnItemInCartModal(response);
            CartController.updateSums(response);
            CartController.setCartEmptyVisibility();
            // Flash line that has been updated
            CartController.flashElement(cart_line, '#2baf2b');
            break;

          case 'remove':
          // Find line to update
            cart_line.fadeOut(500, function() {
              $(this).remove();
              CartController.updateSums(response);
              CartController.setCartEmptyVisibility();
            });
            break;
        }
      })
      .fail(function(/*data*/) {
        PNotify.error({
          title: 'Oida...',
          text:  'Klarte ikke å endre handlekurven... Prøv igjen...',
          icon:  'far fa-shopping-cart',
          delay: 5000,
        });
      });
  }

  /**
   * [authenticityToken Get the Authentication token from the rails app]
   * @return {[String]} [Authentication token from rails]
   */
  static get authenticityToken() {
    return $('meta[name="csrf-token"]').attr('content');
  }

  static updateCartCounter(cart_count) {
    if (cart_count === 0) {
      $('.cart-counter').text('').removeClass('show');
    } else {
      $('.cart-counter').text(cart_count).addClass('show');
    }
  }

  // Update totals on changes...
  static updateSums(response) {
    $('.sum-ex-vat').text(accounting.formatMoney(response.cart.sum, { symbol: 'kr',  format: '%v %s', thousand: ' ', precision: 0 }));
    $('.sum-inc-vat').text(accounting.formatMoney(response.cart.sum_with_vat, { symbol: 'kr',  format: '%v %s', thousand: ' ', precision: 0 }));
  }

  static setCartEmptyVisibility(){
    if ($('.cart-item-table tbody tr').length == 0) {
      CartController.hideCartOnEmptyCart();
    } else {
      CartController.showCartContent();
    }
  }

  static hideCartOnEmptyCart() {
    $('.cart-empty').removeClass('d-none');
    $('.cart-content').addClass('d-none');
  }

  static showCartContent() {
    $('.cart-empty').addClass('d-none');
    $('.cart-content').removeClass('d-none');
  }

  static addItem(event) {
    event.preventDefault();
    CartController.doAjax('add', $(event.currentTarget).data('price-id'));
  }

  static removeItem(price_id) {
    event.preventDefault();
    CartController.doAjax('remove', price_id);
  }

  static increaseItem(event) {
    let data_element = $(event.currentTarget).closest('tr');
    let quantity = parseInt(data_element.attr('data-quantity')) + 1;
    // Don't allow more than 1000 of each item...
    if (quantity > 1000) {
      quantity = 1000;
    }
    CartController.doAjax('quantity', data_element.data('price-id'), quantity);
  }

  static decreaseItem(event) {
    let data_element = $(event.currentTarget).closest('tr');
    let quantity = parseInt(data_element.attr('data-quantity')) - 1;
    let price_id = data_element.data('price-id');
    if (quantity == 0) {
      CartController.removeItem(price_id);
      return;
    }
    CartController.doAjax('quantity', price_id, quantity);
  }

  static setupEventListeners() {
    // this will point to the link clicked
    $('.link-cart-add').on('click', CartController.addItem);
    $('.btn-cart-decrease').on('click', CartController.decreaseItem);
    $('.btn-cart-increase').on('click', CartController.increaseItem);
  }

  static setupCartModal() {
    // TODO CHECK: This should not open when the user is in the 'order process' ?
    if ($('.cart-modal-link').length > 0) {
      $('.cart-modal-link').magnificPopup({
        type:      'inline',
        preloader: false,
        callbacks: {
          open:  function() { CartController.showCartScrollArrows(); },
          close: function() { CartController.hideCartScrollArrows(); },
        },
      });
      CartController._cart_modal = $.magnificPopup.instance;
      jQuery(function($) {
        CartController._cart_modal.close()
      });
    }
  }

  static toggleLoaderInCartModal(visibility = false) {
    if (visibility) {
      $('.cart-loader').removeClass('d-none');
      $('.cart-modal-cart-content').addClass('d-none');
      $('.modal-cart-empty').addClass('d-none');
    } else {
      $('.cart-loader').addClass('d-none');
      if ($('.cart-line').length > 0) {
        $('.cart-modal-cart-content').removeClass('d-none');
      } else {
        $('.modal-cart-empty').removeClass('d-none');
      }
    }
  }

  static openCartModal(loader_visibility = false) {
    CartController.toggleLoaderInCartModal(loader_visibility);
    $('.cart-modal-link').magnificPopup('open');
  }

  static addItemToCartModal(response, cart_line) {
    // Check if item already exists, if so, replace the line!
    $('.modal-cart-empty').addClass('d-none');
    $('.modal-cart-content').removeClass('d-none');
    let cl = null;
    if (cart_line.length > 0) {
      cl = $(response.cart_line_html);
      cart_line.replaceWith(cl);
    } else {
      $('.cart-item-table tbody').append(response.cart_line_html);
      cl = $('.cart-item-table tbody tr').last();
    }
    CartController.updateSums(response);
    CartController.setCartEmptyVisibility();
    // Make sure elemnt is visible!
    $('.mfp-wrap').scrollTop(cl.position().top);
    CartController.flashElement(cl, '#2baf2b');
  }

  static showCartScrollArrows() {
    // CartController._scroll_arrows_visible = true;
    $('.mfp-wrap').on('scroll', CartController.toggleCartScrollArrows);
  }

  static hideCartScrollArrows() {
    // CartController._scroll_arrows_visible = false;
    $('.mfp-wrap').off('scroll', CartController.toggleCartScrollArrows);
  }

  static toggleCartScrollArrows() {
    // At top ?
    if ($('.mfp-wrap').scrollTop() == 0) {
      // hide arrow
      $('.cart-scroll-up-arrow').addClass('d-none');
    } else {
      $('.cart-scroll-up-arrow').removeClass('d-none');
    }

    // more content to scroll down to element?
    if (($('.mfp-wrap').scrollTop() + $('.mfp-wrap').height()) <= ($('.mfp-content').height() - 5)) {
      $('.cart-scroll-down-arrow').removeClass('d-none');
    } else {
      $('.cart-scroll-down-arrow').addClass('d-none');
    }
  }

  static flashElement (jq_element, background_color, callback) {
    if (CartController._flashing == true) {
      return;
    }
    CartController._flashing = true;
    var original_color = jq_element.css('background-color');
    (function (jq_element, original_color) {
      jq_element.animate({
        backgroundColor: background_color,
      },
      300, function(z) {
        /* stuff to do after animation is complete */
        $(this).animate({backgroundColor: original_color}, 300, function() {
          $(this).css('backgroundColor', '');
          CartController._flashing = false;
          if (typeof callback === 'function') { callback(); }
        });
      });
    })(jq_element, original_color);
  }

  // This is already done since view is shared between cart and modal
  // static setQuantityOnItemInCartModal(response) {
  //   // find entry and replace with response entry
  //   $('tr')
  // }

  // In theory this is also already done...
  // static removeItemFromCartModal(response) {
  //
  // }
}

