'use strict';

/**
 * Module dependencies
 */
import decouple from './decouple';
import Emitter from './emitter';

/**
 * Privates
 */
var scrollTimeout;
var scrolling = false;
var doc = window.document;
var html = doc.documentElement;
var msPointerSupported = window.navigator.msPointerEnabled;
var touch = {
  'start': msPointerSupported ? 'MSPointerDown' : 'touchstart',
  'move':  msPointerSupported ? 'MSPointerMove' : 'touchmove',
  'end':   msPointerSupported ? 'MSPointerUp' : 'touchend'
};
var prefix = (function prefix() {
  var regex = /^(Webkit|Khtml|Moz|ms|O)(?=[A-Z])/;
  var styleDeclaration = doc.getElementsByTagName('script')[0].style;
  for (var prop in styleDeclaration) {
    if (regex.test(prop)) {
      return '-' + prop.match(regex)[0].toLowerCase() + '-';
    }
  }
  // Nothing found so far? Webkit does not enumerate over the CSS properties of the style object.
  // However (prop in style) returns the correct value, so we'll have to test for
  // the precence of a specific property
  if ('WebkitOpacity' in styleDeclaration) { return '-webkit-'; }
  if ('KhtmlOpacity' in styleDeclaration) { return '-khtml-'; }
  return '';
}());
function extend(destination, from) {
  for (var prop in from) {
    if (from[prop]) {
      destination[prop] = from[prop];
    }
  }
  return destination;
}
function inherits(child, uber) {
  child.prototype = extend(child.prototype || {}, uber.prototype);
}

/**
 * Slideout constructor
 */
export class Slideout {
  constructor(options) {
    options = options || {};
    // Sets default values
    this._startOffsetX = 0;
    this._currentOffsetX = 0;
    this._opening = false;
    this._moved = false;
    this._opened = false;
    this._preventOpen = false;
    this._touch = options.touch === undefined ? true : options.touch && true;
    // Sets panel
    this.panel = options.panel;
    this.menu = options.menu;
    // Sets  classnames
    if (!this.panel.classList.contains('slideout-panel')) {
      this.panel.classList.add('slideout-panel');
    }
    if (!this.menu.classList.contains('slideout-menu')) {
      this.menu.classList.add('slideout-menu');
    }
    // Sets options
    this._fx = options.fx || 'ease';
    this._duration = parseInt(options.duration, 10) || 300;
    this._tolerance = parseInt(options.tolerance, 10) || 70;
    this._padding = this._translateTo = parseInt(options.padding, 10) || 256;
    this._orientation = options.side === 'right' ? -1 : 1;
    this._translateTo *= this._orientation;
    // Slideover fork feature
    this._mode = options.mode || 'push';
    // Init touch events
    if (this._touch) {
      this._initTouchEvents();
    }
  }
  /**
   * Opens the slideout menu.
   */
  open() {
    var self = this;
    this.emit('beforeopen');
    if (html.className.search('slideout-open') === -1) {
      html.className += ' slideout-open';
    }
    this._setTransition();
    this._translateXTo(this._translateTo);
    this._opened = true;
    setTimeout(function () {
      // Slideover fork feature
      if (this._mode === 'push') {
        self.panel.style.transition = self.panel.style['-webkit-transition'] = '';
      }
      else {
        self.menu.style.transition = self.menu.style['-webkit-transition'] = '';
      }
      self.emit('open');
    }, this._duration + 50);
    return this;
  }
  /**
   * Closes slideout menu.
   */
  close() {
    var self = this;
    if (!this.isOpen() && !this._opening) {
      return this;
    }
    this.emit('beforeclose');
    this._setTransition();
    this._translateXTo(0);
    this._opened = false;
    setTimeout(function () {
      html.className = html.className.replace(/ slideout-open/, '');
      // Slideover fork feature
      if (this._mode === 'push') {
        self.panel.style.transition = self.panel.style['-webkit-transition'] = self.panel.style[prefix + 'transform'] = self.panel.style.transform = '';
      }
      else {
        self.menu.style.transition = self.menu.style['-webkit-transition'] = self.menu.style[prefix + 'transform'] = self.menu.style.transform = '';
      }
      self.emit('close');
    }, this._duration + 50);
    return this;
  }
  /**
   * Toggles (open/close) slideout menu.
   */
  toggle() {
    return this.isOpen() ? this.close() : this.open();
  }
  /**
   * Returns true if the slideout is currently open, and false if it is closed.
   */
  isOpen() {
    return this._opened;
  }
  /**
   * Translates panel and updates currentOffset with a given X point
   */
  _translateXTo(translateX) {
    this._currentOffsetX = translateX;
    // Slideover fork feature
    if (this._mode === 'push') {
      this.panel.style[prefix + 'transform'] = this.panel.style.transform = 'translateX(' + translateX + 'px)';
    }
    else {
      this.menu.style[prefix + 'transform'] = this.menu.style.transform = 'translateX(' + translateX + 'px)';
    }
    return this;
  }
  /**
   * Set transition properties
   */
  _setTransition() {
    // Slideover fork feature
    if (this._mode === 'push') {
      this.panel.style[prefix + 'transition'] = this.panel.style.transition = prefix + 'transform ' + this._duration + 'ms ' + this._fx;
    }
    else {
      this.menu.style[prefix + 'transition'] = this.menu.style.transition = prefix + 'transform ' + this._duration + 'ms ' + this._fx;
    }
    return this;
  }
  /**
   * Initializes touch event
   */
  _initTouchEvents() {
    var self = this;
    /**
     * Decouple scroll event
     */
    this._onScrollFn = decouple(doc, 'scroll', function () {
      if (!self._moved) {
        clearTimeout(scrollTimeout);
        scrolling = true;
        scrollTimeout = setTimeout(function () {
          scrolling = false;
        }, 250);
      }
    });
    /**
     * Prevents touchmove event if slideout is moving
     */
    this._preventMove = function (eve) {
      // Cannot prevent see https://www.chromestatus.com/features/5093566007214080
      // if (self._moved) {
      //   eve.preventDefault();
      // }
    };
    doc.addEventListener(touch.move, this._preventMove);
    /**
     * Resets values on touchstart
     */
    this._resetTouchFn = function (eve) {
      if (typeof eve.touches === 'undefined') {
        return;
      }
      self._moved = false;
      self._opening = false;
      self._startOffsetX = eve.touches[0].pageX;
      // Slideover fork feature
      if (self._mode === 'push') {
        self._preventOpen = (!self._touch || (!self.isOpen() && self.menu.clientWidth !== 0));
      }
      else {
        self._preventOpen = (!self._touch || (!self.isOpen() && self.menu.style.transform !== ''));
      }
    };
    this.panel.addEventListener(touch.start, this._resetTouchFn);
    /**
     * Resets values on touchcancel
     */
    this._onTouchCancelFn = function () {
      self._moved = false;
      self._opening = false;
    };
    this.panel.addEventListener('touchcancel', this._onTouchCancelFn);
    /**
     * Toggles slideout on touchend
     */
    this._onTouchEndFn = function () {
      if (self._moved) {
        self.emit('translateend');
        (self._opening && Math.abs(self._currentOffsetX) > self._tolerance) ? self.open() : self.close();
      }
      self._moved = false;
    };
    this.panel.addEventListener(touch.end, this._onTouchEndFn);
    /**
     * Translates panel on touchmove
     */
    this._onTouchMoveFn = function (eve) {
      if (scrolling || self._preventOpen || typeof eve.touches === 'undefined') {
        return;
      }
      var dif_x = eve.touches[0].clientX - self._startOffsetX;
      var translateX = self._currentOffsetX = dif_x;
      if (Math.abs(translateX) > self._padding) {
        return;
      }
      if (Math.abs(dif_x) > 20) {
        self._opening = true;
        var oriented_dif_x = dif_x * self._orientation;
        if (self._opened && oriented_dif_x > 0 || !self._opened && oriented_dif_x < 0) {
          return;
        }
        if (!self._moved) {
          self.emit('translatestart');
        }
        if (oriented_dif_x <= 0) {
          translateX = dif_x + self._padding * self._orientation;
          self._opening = false;
        }
        if (!self._moved && html.className.search('slideout-open') === -1) {
          html.className += ' slideout-open';
        }
        // Slideover fork feature
        if (self._mode === 'push') {
          self.panel.style[prefix + 'transform'] = self.panel.style.transform = 'translateX(' + translateX + 'px)';
        }
        else {
          self.menu.style[prefix + 'transform'] = self.menu.style.transform = 'translateX(' + translateX + 'px)';
        }
        self.emit('translate', translateX);
        self._moved = true;
      }
    };
    this.panel.addEventListener(touch.move, this._onTouchMoveFn);
    return this;
  }
  /**
   * Enable opening the slideout via touch events.
   */
  enableTouch() {
    this._touch = true;
    return this;
  }
  /**
   * Disable opening the slideout via touch events.
   */
  disableTouch() {
    this._touch = false;
    return this;
  }
  /**
   * Destroy an instance of slideout.
   */
  destroy() {
    // Close before clean
    this.close();
    // Remove event listeners
    doc.removeEventListener(touch.move, this._preventMove);
    this.panel.removeEventListener(touch.start, this._resetTouchFn);
    this.panel.removeEventListener('touchcancel', this._onTouchCancelFn);
    this.panel.removeEventListener(touch.end, this._onTouchEndFn);
    this.panel.removeEventListener(touch.move, this._onTouchMoveFn);
    doc.removeEventListener('scroll', this._onScrollFn);
    // Remove methods
    this.open = this.close = function () { };
    // Return the instance so it can be easily dereferenced
    return this;
  }
}

/**
 * Inherits from Emitter
 */
inherits(Slideout, Emitter);


/**
 * Expose Slideout
 */
//module.exports = Slideout;
