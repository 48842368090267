import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

export default class SweetAlert2Confirm {
  static handleConfirm(e) {
    if (!SweetAlert2Confirm.allowAction(this)) {
      Rails.stopEverything(e);
    }
    Rails.stopEverything(e);
  }

  static allowAction(element) {
    if (element.getAttribute('data-confirm') === null) {
      return true
    }

    SweetAlert2Confirm.showConfirmationDialog(element)
    return false
  }

  // Display the confirmation dialog
  static showConfirmationDialog(element) {
    const title = element.getAttribute('data-confirm')
    const type = element.getAttribute('data-confirmtype')
    const text = element.getAttribute('data-text')
    const confirm_button_color = element.getAttribute('data-confirmbuttoncolor');
    const confirm_button = element.getAttribute('data-confirmbutton');
    const cancel_button = element.getAttribute('data-cancelbutton');

    Swal.fire({
      title:              title || 'Er du sikker?',
      text:               text || '',
      icon:               type || 'warning',
      showCancelButton:   true,
      confirmButtonColor: confirm_button_color || '#DD6B55',
      confirmButtonText:  confirm_button || 'Ja',
      cancelButtonText:   cancel_button || 'Nei. jeg trykket feil!',
    }).then(result => SweetAlert2Confirm.confirmed_ujs_delete(element, result));
  }

  static confirmed_ujs_delete = (element, result) => {
    if (result.value) {
      // User clicked confirm button
      element.removeAttribute('data-confirm')
      element.click()
    }
  }
}
