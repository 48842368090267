import $ from 'jquery';
import 'magnific-popup';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';
import 'corejs-typeahead/dist/typeahead.jquery';
export class Search {
  static start() {
    $('.search-modal-link').magnificPopup({
      type:      'inline',
      preloader: false,
      alignTop:  true,
      focus:     '.modal-search-input',
      callbacks: {
        open: function() {
          if ($('.show-search-input').length > 0) {
            $('.modal-search-input').val($('.show-search-input').val());
          }
        }
      }
    });

    var product_titles = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch:       '/search/typeahead.json'
    });

    // passing in `null` for the `options` arguments will result in the default
    // options being used
    $('.show-search-input').typeahead(
      {
        autoselect: true,
        minLength:  2,
        highlight:  true,
        hint:       false
      },
      {
        name:   'product_titles',
        source: product_titles,
      }
    );

    $('.show-search-input').on('typeahead:selected', function(event) {
      $(this).closest('form').submit();
    })
  }
}
