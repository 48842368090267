import { Slideout } from "./slideout";
import { SymboliaTools } from "../common/symbolia_tools";

export class SlideoutInit {
  static _slideout;
  static close(event) {
    if(SlideoutInit._slideout._opened) {
      event.preventDefault();
      SlideoutInit._slideout.close();
      document.querySelector('#slideout-fader').removeEventListener('click');
      return false;
    }
  }

  static open(event) {
    event.preventDefault();
    document.querySelector('#slideout-fader').addEventListener('click', SlideoutInit.close);
    SlideoutInit._slideout.toggle();
    return false;
  }

  static start() {
    // Setup the slideout menu
    SlideoutInit._slideout = new Slideout({
      panel:     document.body,
      menu:      document.getElementById('slideout-menu'),
      padding:   300,
      tolerance: 70,
      side:      'right',
      mode:      'slide'
    });

    SymboliaTools.listenOnAllElements('click', '.slideout-open-btn', SlideoutInit.open);

    SymboliaTools.listenOnAllElements('click', '.slideout-close-btn', SlideoutInit.close);

    // Toggle class on backdrop
    SlideoutInit._slideout.addEventListener('beforeclose', function() {
      SymboliaTools.removeClass('#slideout-fader', 'slideout-visible');
    });


    SlideoutInit._slideout.addEventListener('beforeopen', function() {
      SymboliaTools.addClass('#slideout-fader', 'slideout-visible');
    });

    SymboliaTools.removeClass('.slideout-menu', 'd-none');

    // For automatic opening when testing:
    // setTimeout(function () {
    //   slideout.open()
    //
    // }, 50);
  }
};
