export class ImageGallery {
  static __sti_gallery = null;
  constructor() {
    this.initSettings();
    this.setupEventListeners();
  }

  initSettings() {
    this._main_slick_slider = null;
    // this._thumb_slick_slider = null;
    this._magnific_popup = null;
    this._opening = false;
    this._gallery_created = false;
    this._gallery_data = '';
    this._gallery_image_id_array = [];
  }

  createImageArray() {
    var _$el, ret = [];
    // Only select visible items
    $('.image-gallery-image:visible').not('.slick-cloned').each(function(index, el) {
      _$el = $(el);
      ret.push({
        src:         _$el.data('image-gallery-src'),
        // thumb_src:   _$el.data('image-gallery-thumb-src'),
        w:           _$el.data('width'),
        h:           _$el.data('height'),
        title:       _$el.data('title'),
        description: _$el.data('title'),
        index:       _$el.data('image-index')
      });
    });
    return ret;
  }

  setupEventListeners() {
    $(document).on('click', '.image-gallery-image', $.proxy(this.showImageInGallery, this));
  }

  removeEventListeners() {
    $(document).off('click', '.image-gallery-image', $.proxy(this.showImageInGallery, this));
  }

  createSingleElement(image_data) {
    return `<div class="slide-with-caption text-center" data-image-id="${image_data.index}">
      <div class="slide-content-outer">
        <div class="slide-content--flexbox">
          <div class="slide-content--spacer--upper">&nbsp;</div>
          <img class="img-99-responsive slide-content--img" data-lazy="${image_data.src}" />
          <div class="slide-content--spacer--lower">&nbsp;</div>
          <div class="slide-content--description">
            <h4 class="slide-title">
            ${image_data.title}
            </h4>
            <p class="slide-description d-none">
            ${image_data.description}
            </p>
          </div>
        </div>
      </div>
    </div>`
  }

  showImageInGallery(event) {
    if (this._opening === true) {
      return
    }
    var initial_index;
    this._opening = true;
    this.createGallery();
    initial_index = this.indexFromImageIndex($(event.currentTarget).data('image-index'));
    this.createPopup(initial_index);
    // this.createGalleryAndGoto(parseInt($(event.currentTarget).data('image-index')));
    // this._gallery.init();
    // Goto seems to have an issue?
    // this._gallery.goTo(parseInt($(event.target).data('imageIndex')));
    this._opening = false;
  }

  indexFromImageIndex(image_index) {
    // return 0 for invalid indexes
    if (this._gallery_image_id_array.indexOf(image_index) == -1) {
      return 0;
    }
    return this._gallery_image_id_array.indexOf(image_index);
  }

  createGallery() {
    // console.log('ImageGallery: creating...');
    this._gallery_created = true;
    this._gallery_data = '<div id="slick-gallery-outer-holder"><div id="slick-gallery-holder">';
    for (let image_data of this.createImageArray()) {
      // console.log(image_data);
      this._gallery_image_id_array.push(image_data.index);
      this._gallery_data += this.createSingleElement(image_data);
    }
    this._gallery_data += '</div></div>'
    // console.log('ImageGallery: created...');
    // this._gallery.listen('destroy', $.proxy(this.nullGallery, this));
  }

  createPopup(initial_index) {
    $.magnificPopup.open({
      // removalDelay: 300,
      mainClass: 'mfp-slick-gallery',

      items: [
        {
          src:  this._gallery_data,
          type: 'inline'
        }
      ],
      gallery: {
        enabled: false
      },

      callbacks: {
        beforeOpen: function() {

        },
        open: function() {
          $('#slick-gallery-holder').slick({
            rows:         0,
            dots:         true,
            initialSlide: initial_index
          })
        }
      }
    });
    this._magnific_popup = $.magnificPopup.instance;
  }

  destroy() {
    // console.log('ImageGallery: Destroying ...');
    // Destroy slick sliders
    this.removeEventListeners();
    if (this._main_slick_slider !== null) {
      this._main_slick_slider.slick('unslick');
    }
    // Destroy magnificent popup
    if (this._magnific_popup !== null) {
      this._magnific_popup.close();
    }
    // this.initSettings();
    // console.log('ImageGallery: Destroyed!');
  }

  static start() {
    if (ImageGallery.__sti_gallery != null) {
      ImageGallery.__sti_gallery.destroy();
      ImageGallery.__sti_gallery = null;
    }
    if ($('.image-gallery-image').length > 0) {
      ImageGallery.__image_gallery = new ImageGallery();
    }
  }
}

