import $ from 'jquery';
import 'magnific-popup';
export class User {
  static initPopup() {
    if ($('.user-sign-in-nav').length > 0) {
      $('.user-sign-in-nav').magnificPopup({
        type:      'inline',
        preloader: false,
        focus:     '#email',
        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
          beforeOpen: function() {
            if($(window).width() < 700) {
              this.st.focus = false;
            } else {
              this.st.focus = '#email';
            }
          }
        }
      });
    }
  }

  static start() {
    User.initPopup();
  }
}
