/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')

import Rails from '@rails/ujs';
import SweetAlert2Confirm from 'application/sweetalert2-confirm';

Rails.delegate(document, 'a[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)
Rails.delegate(document, 'button[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)
Rails.delegate(document, 'input[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)

// import 'application/sweetalert2-confirm';
// import 'promise-polyfill/src/polyfill';

// External imports

// import 'bootstrap'
// import 'bootstrap/transition'
import 'bootstrap/js/src/button'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/alert'
import 'bootstrap/js/src/tooltip'
// Replace current pnotify?
// import 'bootstrap/js/src/toast'

// import 'jquery-lazyload'

// // Internal imports?
import { SymboliaTools } from 'common/symbolia_tools';

import { SlideoutInit } from 'slideout/init';

import { StickyNav } from 'application/sticky-nav';

import { SlickSliders } from 'application/slick_sliders';

// import Turbolinks from 'turbolinks';

import { Search } from 'application/search';

import { ShowDate } from 'application/show_date';

import { ImageGallery } from 'image/image_gallery';

import { Prices } from 'application/prices';
Prices.start();

import { SymSorter } from 'application/sorter';

import { User } from 'application/user'

// import 'sweetalert2'
// // TODO:
// // # https://dev.to/peterfication/how-to-use-sweetalert2-for-your-rails-51-rails-ujs-confirms-withoutjquery-67h
// // # gem 'sweet-alert-confirm'

// // # Use one of these, and copy js to yarn:
// // # https://github.com/JoeWoodward/rails-sweetalert2-confirm
// // # gem 'rails-sweetalert2-confirm'

// // # https://github.com/nicolasblanco/sweet-alert2-rails
// // # gem 'sweet-alert2-rails'

// Goes in separate pack.
// import 'order'
// import 'shipping'
// import 'payment'

// Import again when stuff is working..
// // import 'tawk_data'

import { VideoPopup } from 'application/video_popup'
VideoPopup.setup();

import { CartController } from 'order/cart';

import { AddressAutocomplete } from 'common/address_autocomplete'

// TODO: rewrite point trigger/config.
import { PointTrigger } from 'point/point_trigger'

function application_setup() {
  SymboliaTools.startLazyLoad();
  SlideoutInit.start();
  PointTrigger.start();
  StickyNav.start();
  SlickSliders.start();
  Search.start();
  ShowDate.start();
  ImageGallery.start();
  SymSorter.start();
  User.start();
  CartController.setup();
  AddressAutocomplete.start();
  console.log('Done initializing (doc ready)');
}

document.addEventListener('DOMContentLoaded', application_setup);


// TODO:
// Jquery UI + jquery fileupload
Rails.start();
// Turbolinks.start();
