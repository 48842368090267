export class StickyNav {
  static start() {
    $(window).scroll(function() {
      if((window.pageYOffset || document.documentElement.scrollTop) < 60) {
        $('header').removeClass('smaller');
        $('.header-spacer').removeClass('smaller');
        $('.sticky-show').hide(150);
        $('.sticky-hide').show(150);
      } else {
        $('header').addClass('smaller');
        $('.header-spacer').addClass('smaller');
        $('.sticky-show').show(150);
        $('.sticky-hide').hide(150);
      }
    });
  }
}
