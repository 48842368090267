// Bruker: ws.geonorge.no for å automatisk få addresser ut fra å skrive streng
// https://ws.geonorge.no/AdresseWS/adresse/sok?sokestreng=eik%C3%A5svei%2018%nesod

// For DK: http://dawa.aws.dk/
// https://github.com/DanmarksAdresser/dawa-autocomplete2

// Se også her:
// https://github.com/sgruhier/typeahead-addresspicker/blob/master/src/typeahead-addresspicker.coffee

// For Postbokser, legg inn DB med postnumre og 'godta' ordet Postboks + tall + slå opp postnummer
// Manuell parsing av adresse som har postboks
// Legg inn 'manuell' adresse entry om det trengs

// Bruk Bloodhound for addresser
// Bruk typeahead.js :)

// For nå: Bruke /typeahead-addresspicker.coffee konvertert til ES6

import $ from 'jquery';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';
import 'corejs-typeahead/dist/typeahead.jquery';

export class AddressAutocomplete {
  static __address_autocomplete
  static start() {
    if ($('.search-address').length > 0) {
      new AddressAutocomplete();
    }
  }
  // Ensure this is a singleton
  constructor() {
    AddressAutocomplete.__address_autocomplete = this;
    this.bloodhound = null;
    this.setupEventListeners();
  }

  isDanishLocale(){
    if ($('.i18n-locale').val() == 'da') {
      return true;
    }
    return false;
  }

  bloodHound() {
    if (this.bloodhound !== null){
      return this.bloodhound;
    }
    if (this.isDanishLocale()) {
      this.bloodhound = this.bloodHoundDk();
    } else {
      this.bloodhound = this.bloodHoundNo();
    }
    return this.bloodhound;
  }

  bloodHoundDk() {
    return new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,

      remote: {
        wildcard:  '%QUERY',
        // Autocomplete api does not provide positions
        // url:       'https://dawa.aws.dk/adresser/autocomplete?q=%QUERY',
        url:       'https://dawa.aws.dk/adresser?per_side=20&q=%QUERY*',
        transform: this.parseResponseDk
      }
    })
  }

  bloodHoundNo() {
    return new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,

      remote: {
        wildcard:  '%QUERY',
        url:       'https://ws.geonorge.no/adresser/v1/sok?treffPerSide=20&sok=%QUERY*',
        transform: this.parseResponseNO
      }
    })
  }

  parseResponseNO(response) {
    let ret = [];

    if (response['totaltAntallTreff'] == 0)
      return ret;

    response['adresser'].forEach( a => {
      a['completeAddress'] = a['adressetekst']
      a['street_address1'] = a['adressetekst'];
      a['completeAddress'] += `, ${a['postnummer']}`;
      a['postcode'] = a['postnummer'];
      a['city'] = a['poststed'].charAt(0).toUpperCase() + a['poststed'].slice(1).toLowerCase();
      a['completeAddress'] += ` ${a['city']}`;
      a['country'] = 'NO';
      if ('representasjonspunkt' in a) {
        a['position'] = `${a['representasjonspunkt']['lat']} ${a['representasjonspunkt']['lon']}`;
      }
      ret.push(a);
    })
    return ret;
  }

  parseResponseDk(response) {
    let ret = [];
    response.forEach( a => {
      if ((typeof(a['adgangsadresse']) == 'undefined') || (a['adgangsadresse'] == null)) {
        return
      }
      if ((typeof(a['adgangsadresse']['vejstykke']) == 'undefined') || (a['adgangsadresse']['vejstykke'] == null)) {
        return
      }
      let nested_adress = a['adgangsadresse'];
      a['completeAddress'] = a['adressebetegnelse'];
      a['street_address1'] = nested_adress['vejstykke']['adresseringsnavn'];
      if (nested_adress['husnr'] != null) {
        a['street_address1'] += ` ${nested_adress['husnr']}`;
      }
      if (a['etage'] != null) {
        a['street_address1'] += ` ${nested_adress['etage']}`;
      }
      if (a['dør'] != null) {
        a['street_address1'] += ` ${nested_adress['dør']}`;
      }
      a['postcode'] = nested_adress['postnummer']['nr'];
      a['city'] = nested_adress['postnummer']['navn'];
      a['county'] = nested_adress['region']['navn'];
      a['country'] = 'DK';
      a['position'] = a['adgangsadresse']['adgangspunkt']['koordinater'].reverse().join(' ');
      ret.push(a);
    });
    return ret;
  }

  selectedAddress(selected_event, result) {
    this.fillInForm($(selected_event.currentTarget).closest('form'), result)
  }

  fillInForm(form, result) {
    $(form).find('.street_address1').val(result['street_address1']);
    $(form).find('.postcode').val(result['postcode']);
    $(form).find('.city').val(result['city']);
    $(form).find('.country').val(result['country']);
    if (typeof(result['county']) != 'undefined') {
      $(form).find('.county').val(result['county']);
    };
    $(form).find('.position').val(result['position']);
  }

  toggleManualAddress(event) {
    event.preventDefault();
    $('.manual-address-input').toggle();
  }

  removeEventListeners() {
    $('.manual-address-toggler').off('click');
    $('.search-address').off('typeahead:selected');
    $('.search-address').off('typeahead:cursorchanged');
  }

  setupEventListeners() {
    $('.search-address').typeahead({
      autoselect: true,
      minLength:  2,
      highlight:  true,
      hint:       'Skriv inn adressen for forslag...'
    },{
      displayKey: 'completeAddress',
      source:     this.bloodHound()
    })
    $(document).on('click', '.manual-address-toggler', $.proxy(this.toggleManualAddress, this));
    $('.search-address').on('typeahead:selected', $.proxy(this.selectedAddress, this));
    $('.search-address').on('typeahead:cursorchanged', $.proxy(this.selectedAddress, this));
  }
}

// class AddressNoBloodhound extends Bloodhound {
//   constructor(options){
//     super(options)
//   }
//   get(query, cb) {
//     $.ajax
//   }
// }
//
// class AddressDkBloodhound extends Bloodhound {
//   constructor(options){
//     super(options)
//   }
//
// }

