export class VideoPopup {
  static setup() {
    VideoPopup.setupEventListeners();
  }

  static openPopup(video_link) {
    let youtube_id = $(video_link).attr('data-youtube-id');
    let autoplay = $(video_link).attr('data-autoplay');
    let show_related = $(video_link).attr('data-show-related');
    let src = `https://www.youtube.com/watch?v=${youtube_id}?autoplay=${autoplay}&rel=${show_related}`;
    let embed_src = `//www.youtube.com/embed/${youtube_id}?autoplay=${autoplay}&rel=${show_related}`;
    console.log(`Youtube id: ${youtube_id}`)
    $.magnificPopup.open({
      items: [{
        src:  src,
        type: 'iframe'
      }],
      iframe: {
        markup: '<div class="mfp-iframe-double-outer"><div class="mfp-iframe-outer">'+
                  '<div class="mfp-iframe-scaler">'+
                  '<div class="mfp-close-outer"><div class="mfp-close"></div></div>'+
                  '<iframe class="mfp-iframe" frameborder="0" allow="autoplay;" allowfullscreen></iframe>'+
                '</div></div></div>',
        patterns: {
          youtube: {
            index: 'youtube.com',
            id:    youtube_id,
            src:   embed_src,
          }
        }
      },
      closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close fa fa-times"></button>',

      // closeMarkup: '<i class="fa fa-times"></i>',
      // disableOn: 700,
      type:         'iframe',
      mainClass:    'mfp-fade',
      removalDelay: 160,
      preloader:    false
    });
  }

  static setupEventListeners() {
    $(document).on('click', '.play-youtube-video', function(event) {
      event.preventDefault();
      VideoPopup.openPopup(event.currentTarget);
    })
  }
}