import lozad from 'lozad';

// Tools for symbolia sites
export class SymboliaTools {

  static classEventListener(event_type, classname, fn) {
    document.addEventListener(event_type, function(e){
      if(e.target.classList.contains(classname) || e.target.closest(`.${classname}`)) {
        fn(e);
      }
    });
  }

  // Check if value is integer
  static isInt(value) {
    var x;
    if (isNaN(value)) {
      return false;
    }
    x = parseFloat(value);
    return (x | 0) === x;
  }

  static startLazyLoad() {
    const observer = lozad(); // passing a `NodeList` (e.g. `document.querySelectorAll()`) is also valid
    observer.observe();
  }

  // See also https://dev.to/akhil_001/adding-event-listeners-to-the-future-dom-elements-using-event-bubbling-3cp1
  static addListenerToFutureElements(parent, eventName, childSelector, cb){
    parent.addEventListener(eventName, function(event){
      const clickedElement = event.target;
      const matchingChild = clickedElement.closest(childSelector);
      if (matchingChild) {
        cb(matchingChild)
      }
    });
  };

  static listenOnAllElements(eventName, selector, cb){
    Array.from(document.querySelectorAll(selector)).forEach((el) => {
      el.addEventListener(eventName, cb);
    });
  };

  static replaceClass(elements, oldClass, newClass) {
    if (!elements) { return; }

    // query if string/selector given instead of objects
    if (typeof(elements) === 'string') {
      elements = document.querySelectorAll(elements);
    }
    // single element?
    else if (elements.tagName) { elements=[elements]; }

    for (var i=0; i<elements.length; i++) {
      elements[i].classList.replace(oldClass, newClass)
    }
  }

  static changeClass(elements, mClass, add = true) {
    if (!elements) { return; }

    // query if string/selector given instead of objects
    if (typeof(elements) === 'string') {
      elements = document.querySelectorAll(elements);
    }
    // single element?
    else if (elements.tagName) { elements=[elements]; }

    for (var i=0; i<elements.length; i++) {
      if (add) {
        Array.isArray(mClass) ? elements[i].classList.add(...mClass) : elements[i].classList.add(mClass);
      } else {
        Array.isArray(mClass) ? elements[i].classList.remove(...mClass) : elements[i].classList.remove(mClass);
      }
    }
  }

  static addClass(elements, mClass) {
    this.changeClass(elements, mClass, true)
  }

  static removeClass(elements, mClass) {
    this.changeClass(elements, mClass, false)
  }

}
